// Determin prize value and type for sweepstake

export const useSweepstakePrizeValue = (totalFunds, currencyCode, prize) => {
  let prizeComp = {
    value: null,
    symbol: null,
    type: null,
  };
  if (totalFunds) { // Lootbox
    prizeComp = {
      value: totalFunds,
      symbol: currencyCode,
      type: 'TotalFunds',
    };
  } else if (prize?.type === 'NFT') { // NFT comps
    prizeComp = {
      value: prize?.value,
      symbol: 'ETH',
      type: 'NFT',
    };
  } else if (prize?.type === 'StakedCash') { // StakedCash comps
    prizeComp = {
      value: prize?.value || prize?.amount,
      symbol: prize?.currencyCode,
      type: 'StakedCash',
    };
  } else {
    prizeComp = {
      value: prize?.amount,
      symbol: prize?.symbol || prize?.currencyCode,
      type: prize?.type,
    };
  }
  return prizeComp;
};
