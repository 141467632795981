<template>
  <div class="relative w-full mt-1.5">
    <button
      v-if="showArrows"
      class="js-block-card-action text-blue-600 absolute top-1/2 left-0 z-20 w-6 h-6 -mt-3 lg:active:translate-y-[1px] hover:text-blue-500 icon-ico-arrow-left-solid font-bold"
      @click.prevent="switchIndex('prev')"
    />

    <button
      v-if="showArrows"
      class="js-block-card-action text-blue-600 absolute top-1/2 right-0 z-20 w-6 h-6 -mt-3 lg:active:translate-y-[1px] hover:text-blue-500 icon-ico-arrow-right-solid font-bold"
      @click.prevent="switchIndex('next')"
    />

    <div
      :key="'key-' + currentIndex"
      class="font-bold whitespace-nowrap text-center animate-fade-in"
      :class="colorTheme === 'dark' ? 'text-white' : 'text-slate-800'"
    >
      <span
        :key="'key-' + uiSettingsPrizeValueCurrency"
        class="leading-none inline-block"
        :class="[
          !compact ? 'max-2sm:text-base text-xl' : '',
          valueAnimationActive ? 'animate-scale-up-twice-mega' : '',
        ]"
      >
        {{ prizeDisplay }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue';
import { storeToRefs } from 'pinia';
import { useUiStore } from '@/store/ui';
import { useCryptoStore } from '@/store/crypto';
import { useSweepstakePrizeValue } from '@/utils/useSweepstakePrizeValue.js';

const { prizes, totalFunds, currencyCode, colorTheme, compact, autoPlay, animateValueUpdate, } = defineProps({
  prizes: {
    type: Array,
    default: null,
  },
  totalFunds: {
    type: [String, Number,],
    default: null,
  },
  currencyCode: {
    type: String,
    default: null,
  },
  colorTheme: {
    type: String,
    default: null,
  },
  compact: {
    type: Boolean,
    default: false,
  },
  autoPlay: {
    type: Boolean,
    default: false,
  },
  animateValueUpdate: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits([
  'set-index',
  'interaction-trigger',
]);

const uiStore = useUiStore();
const {
  uiSettings,
} = storeToRefs(uiStore);

const cryptoStore = useCryptoStore();
const {
  rates,
} = storeToRefs(cryptoStore);

const { $formatMoney, $truncateNumber, $toBigNumber, } = useNuxtApp();

const currentIndex = ref(0);
const secondsCount = ref(-2);
const isAutoPlay = ref(false);
const firstTimeCount = ref(true);
const valueAnimationActive = ref(false);

const uiSettingsPrizeValueCurrency = computed(() => uiSettings.value?.prizeValueCurrency || 'Crypto');
const prizesFiltered = computed(() => prizes?.filter(prize => !prize.reward) || []);
const prizeFilteredCurrent = computed(() => prizesFiltered.value[currentIndex.value] || null);
const prize = computed(() => useSweepstakePrizeValue(totalFunds, currencyCode, prizeFilteredCurrent.value));
const showArrows = computed(() => prizesFiltered.value?.length > 1);
const prizeDisplay = computed(() => {
  if (prizeFilteredCurrent.value?.prizeValueOverride) {
    return prizeFilteredCurrent.value.prizeValueOverride;
  }
  if (prize.value.symbol === 'USD') {
    if (prize.value.value < 100) {
      return $formatMoney(prize.value.value, 'USD', true);
    } else {
      return `$${$truncateNumber(prize.value.value, 0, true)}`;
    }
  }

  const displayCrypto = prize.value.type === 'StakedToken'
    ? `${$formatMoney(prize.value.value)} ${prize.value?.symbol}`
    : `${$truncateNumber(prize.value.value, 6)} ${prize.value?.symbol}`;

  if (uiSettingsPrizeValueCurrency.value === 'Crypto') {
    return displayCrypto;
  } else {
    if (!rates.value) { return displayCrypto; }
    const rateUsd = rates.value[prize.value.symbol]?.USD || null;
    if (!rateUsd) { return displayCrypto; }
    const rateUsdBn = $toBigNumber(rateUsd);
    const valCryptoBn = $toBigNumber(prize.value.value);
    const valUsd = valCryptoBn.multipliedBy(rateUsdBn).decimalPlaces(2).toNumber();

    if (valUsd < 100) {
      return `${$formatMoney(valUsd, 'USD', true)}`;
    } else {
      return `$${$truncateNumber(valUsd, 0, true)}`;
    }
  }
});

function start() {
  if (document.visibilityState === 'visible') {
    currentIndex.value = 0;
    secondsCount.value = -2;
    isAutoPlay.value = false;
    firstTimeCount.value = true;
    emits('set-index', { index: currentIndex.value, direction: 'next', });
    if (autoPlay) {
      isAutoPlay.value = true;
    }
  }
}

function setNewIndex(direction) {
  if (direction === 'next') {
    currentIndex.value = currentIndex.value + 1;
  } else if (direction === 'prev') {
    currentIndex.value = currentIndex.value - 1;
  }
  if (currentIndex.value < 0) {
    currentIndex.value = prizesFiltered.value.length - 1;
  }
  if (currentIndex.value >= prizesFiltered.value.length) {
    currentIndex.value = 0;
  }
  emits('set-index', { index: currentIndex.value, direction, });
}

function switchIndex(direction) {
  isAutoPlay.value = false;
  setNewIndex(direction);
  emits('interaction-trigger');
}

onMounted(() => {
  start();
  document.addEventListener('visibilitychange', start);
});

onBeforeUnmount(() => {
  document.removeEventListener('visibilitychange', start);
});

watch(
  () => uiStore.secondsTicker,
  () => {
    if (!isAutoPlay.value || prizesFiltered.value.length < 2) { return; }
    secondsCount.value++;

    if (secondsCount.value === 3) {
      secondsCount.value = 0;
      setNewIndex('next');
    }
  }
);

watch(
  () => totalFunds,
  () => {
    if (!animateValueUpdate) { return; }
    valueAnimationActive.value = true;
    setTimeout(() => {
      valueAnimationActive.value = false;
    }, 800);
  }
);
</script>
