<template>
  <article
    class="gtm-card-competition flex flex-col rounded-xl transition-all duration-200 relative group/card"
    :class="[
      forceLinkToCompetition ? 'cursor-pointer' : '',
      colorTheme === 'dark' ? 'bg-slate-900' : 'bg-white',
      metadata?.placeholder?.externalLink ? 'cursor-pointer' : '',
      cardSize ? cardSize : 'card-medium'
    ]"
    @click.stop="cardAction($event)"
  >
    <CardRaffleLabelOvertime v-if="overtimeActive"/>

    <!-- Top right corner badges -->
    <div
      class="absolute z-10 top-[7px] right-[6px] sm:top-[8px] flex flex-col items-end"
      :class="{'!top-4': overtimeActive, '!top-2': drawingStateUi, '!top-[13px]': showMultiPrizeComponents}"
    >
      <CardRaffleLabelText
        v-if="metadata?.labelText || showMultiPrizeComponents"
        :label-text="metadata?.labelText || `${prizes.length} Prizes`"
        :label-text-color="metadata?.labelTextColor"
        :label-color="metadata?.labelColor"
        :label-color-shade="metadata?.labelColorShade"
        :info-modal-id="metadata?.infoModalId"
      />

      <CardRaffleLabelEnterState
        v-if="userEnterState"
        :user-enter-state="userEnterState"
        :state="stateComp"
      />
    </div>

    <CardRaffleLabelRequirements
      :nft-requirement="nftRequirement"
      :password-required="passwordRequired"
      :user-tag-required="userTagRequired"
      :state="stateComp"
      :overtime-active="overtimeActive"
      :show-multi-prize-components="showMultiPrizeComponents"
      :user-enter-state="userEnterState"
      :metadata="metadata"
      :erc20-requirement="erc20Requirement"
      :uncx-staking-requirement="uncxStakingRequirement"
    />

    <figure class="w-full rounded-xl overflow-hidden relative z-0 group -top-[0.5px] border-2 border-transparent">
      <NuxtLink
        :to="landingLink"
        :target="metadata?.placeholder?.externalLink ? '_blank' : null"
        class="relative block"
        @click.stop=""
      >
        <div class="pt-[100%] w-full relative -z-10 opacity-0"/>

        <ImageLoader
          v-if="!showMultiPrizeComponents"
          class="!absolute top-0 left-0 w-full h-full"
          :classes="[
            'object-cover h-full w-full block relative z-10',
            drawingStateUi || overtimeActive || isHot ? 'p-1 rounded-2xl' : '',
          ]"
          :image-url="$imgSrcResize(prize?.imageUrl, 350, 350)"
          :animation-url="animationUrl"
          alt="Competition image"
          :width="350"
          :height="350"
          :loading-type="imageLoadingType"
          allow-video-on-hover
          :force-video-on-hover="forceVideoOnHover"
          :block-video-autoplay="blockVideoAutoplay"
          :force-video-autoplay="metadata?.forceVideoAutoplay"
        />
        <ImageCarousel
          v-else
          :prizes="prizesComp"
          class="!absolute top-0 left-0 w-full h-full"
          :padded="drawingStateUi || overtimeActive || isHot"
          :width="350"
          :height="350"
          allow-force-index
          :force-index="currentPrizeIndex"
          :force-direction="currentPrizeDirection"
          :carousel-interacted="prizeCarouselInteracted"
          allow-video-on-hover
          :force-video-on-hover="forceVideoOnHover"
          :block-video-autoplay="blockVideoAutoplay"
          :force-video-autoplay="metadata?.forceVideoAutoplay"
        />

        <ImageLoader
          v-if="supplimentImageUrl"
          class="!absolute top-1 left-1 !w-14 !h-14 after:content-['+'] after:block after:absolute after:-top-[3px] after:-right-[3px] after:z-10 after:bg-white after:leading-none after:px-[3px] after:text-blue-500 after:rounded-full after:font-bold"
          :classes="[
            'object-cover h-full w-full block relative z-10 rounded-lg border border-slate-500'
          ]"
          :image-url="$imgSrcResize(supplimentImageUrl, 150, 150)"
          alt="Competition suppliment image"
          :width="150"
          :height="150"
          :loading-type="imageLoadingType"
        />

        <CardRaffleBorderAnimation
          v-if="drawingStateUi || overtimeActive || isHot"
          :drawing-state-ui="drawingStateUi"
          :overtime-active="overtimeActive"
          :is-hot="isHot"
          :color-theme="colorTheme"
        />

        <CardRaffleCountdownEnter
          v-if="stateComp === 3 && closeTime"
          :close-time="closeTime"
          :is-closed="isClosed"
          :compact="compact"
          force-countdown-display
          :overtime="overtime"
        />

        <CardRaffleCountdownDraw
          v-if="stateComp === 4 && drawTime"
          :draw-time="drawTime"
          :compact="compact"
          :overtime="overtime"
        />

        <CardRaffleLabelInternal v-if="!isPublic"/>

        <div v-if="false && blockchain" class="absolute top-2 left-2 z-20">
          <SvgIcon :icon="blockchain.network || 'Ethereum'"/>
        </div>

        <CardRaffleLabelToken v-if="tokenId" :token-id="tokenId" :compact="compact"/>

        <CardRaffleLabelMore v-if="landingLink" :color-theme="colorTheme">
          More Info
        </CardRaffleLabelMore>
      </NuxtLink>
    </figure>

    <div class="pt-2 overflow-hidden flex flex-col justify-between flex-grow">
      <div class="flex flex-col">
        <div
          class="grow overflow-hidden relative after:h-full after:w-4 after:right-0 after:top-0 after:absolute after:bg-gradient-to-r"
          :class="[
            colorTheme === 'dark' ? 'after:from-slate-600/0 after:to-slate-900/100' : 'after:from-white/0 after:to-white/100',
          ]"
        >
          <CardRaffleTextName
            :to="`/collection/${prize?.collectionSlug}`"
            :prize="prize"
            :color-theme="colorTheme"
            :sweepstake-name="name"
            :force-link-to-competition="forceLinkToCompetition"
          />
          <CurrencyDisplayCard
            :prizes="prizesComp"
            :total-funds="totalFunds"
            :currency-code="currencyCode"
            :color-theme="colorTheme"
            :compact="compact"
            auto-play
            @set-index="setPrizeIndex"
            @interaction-trigger="prizeCarouselInteracted = true"
          />
        </div>
      </div>

      <CardRaffleFooterCompetition
        v-if="!(stateComp == 8 && winners)"
        :color-theme="colorTheme"
        :metadata="metadata"
        :close-condition="closeCondition"
        :entry-estimate="entryEstimate"
        :winner="!!winner"
        :estimated-entry-count="estimatedEntryCount"
        :state="stateComp"
      />

      <CardRaffleMainWinner
        v-if="stateComp == 8 && winners"
        :winners="winners"
        :class="{'mt-1.5' : hideEndedButton}"
      />

      <CardRaffleWaitingBar
        v-if="stateComp === 2"
        :open-time="openTime"
      />

      <CardRaffleButtonClosed
        v-if="showClosedButton"
        :state="stateComp"
        :is-full="isFull"
        :winner="!!winner"
        :winner-date="winnerDate"
        :close-time="closeTime"
      />

      <CardRaffleButtonEnter
        v-if="stateComp === 3"
        :close-time="closeTime"
        :is-closed="isClosed"
        :metadata="metadata"
        :overtime="overtime"
        :is-full="isFull"
        :participants="participants"
        @click="cardAction($event)"
      />
    </div>
  </article>
</template>

<script setup>
import { storeToRefs } from 'pinia';

import { useUserStore } from '@/store/user';
import { useSweepstakeStore } from '@/store/sweepstake';
import { useDeviceStore } from '@/store/device';
import { useUiStore } from '@/store/ui';
import { useSweepstakePrizes } from '@/utils/useSweepstakePrizes';

const props = defineProps({
  colorTheme: {
    type: String,
    default: null,
  },
  compact: {
    type: Boolean,
    default: null,
  },
  imageUrl: {
    type: String,
    default: null,
  },
  name: {
    type: String,
    default: null,
  },
  blockchain: {
    type: Object,
    default: null,
  },
  entryEstimate: {
    type: Object,
    default: null,
  },
  closeCondition: {
    type: Object,
    default: null,
  },
  prizes: {
    type: Array,
    default: null,
  },
  entries: {
    type: Array,
    default: null,
  },
  state: {
    type: [String, Number,],
    default: null,
  },
  userEnterState: {
    type: String,
    default: null,
  },
  winners: {
    type: Array,
    default: null,
  },
  metadata: {
    type: Object,
    default: null,
  },
  id: {
    type: [String, Number,],
    default: null,
  },
  closeTime: {
    type: String,
    default: null,
  },
  openTime: {
    type: String,
    default: null,
  },
  drawTime: {
    type: String,
    default: null,
  },
  entryCount: {
    type: Number,
    default: null,
  },
  participants: {
    type: Number,
    default: null,
  },
  forceLinkToCompetition: {
    type: Boolean,
    default: null,
  },
  overtime: {
    type: Object,
    default: null,
  },
  public: {
    type: Boolean,
    default: false,
  },
  imageLoadingType: {
    type: String,
    default: null,
  },
  assets: {
    type: Array,
    default: null,
  },
  reminder: {
    type: Boolean,
    default: null,
  },
  category: {
    type: Object,
    default: null,
  },
  forceVideoOnHover: {
    type: Boolean,
    default: null,
  },
  blockVideoAutoplay: {
    type: Boolean,
    default: null,
  },
  hideEndedButton: {
    type: Boolean,
    default: null,
  },
  presentationType: {
    type: String,
    default: null,
  },
  mechanism: {
    type: String,
    default: null,
  },
  totalFunds: {
    type: [Number, String,],
    default: null,
  },
  currencyCode: {
    type: String,
    default: null,
  },
  cardSize: {
    type: String,
    default: null,
  },
});

const emits = defineEmits([
  'card-action',
]);

const router = useRouter();
const { $getNFTTokenNumber, $dayjs, } = useNuxtApp();

const sweepstakeStore = useSweepstakeStore();
const {
  passwordsPassed,
} = storeToRefs(sweepstakeStore);
const {
  checkRequirementsPass,
  checkERC20RequirementsPass,
  checkUNCXStakingPass,
} = sweepstakeStore;

const userStore = useUserStore();
const {
  userData,
} = storeToRefs(userStore);

const deviceStore = useDeviceStore();
const {
  isMobileDevice,
} = storeToRefs(deviceStore);

const uiStore = useUiStore();
const {
  secondsTicker,
} = storeToRefs(uiStore);

const { handleOpenNewTab, } = useNavigation();

const nftRequirement = reactive({
  exist: false,
  passes: true,
  items: [],
});

const erc20Requirement = reactive({
  exists: false,
  pass: true,
  imageUrl: '',
  contractAddress: '',
  network: '',
  name: '',
});

const uncxStakingRequirement = reactive({
  exists: false,
  pass: true,
  imageUrl: '',
  contractAddress: '',
  network: '',
  name: '',
});

const isClosed = ref(false);
const hasReminder = ref(null);
const currentPrizeIndex = ref(0);
const currentPrizeDirection = ref('next');
const prizeCarouselInteracted = ref(false);

const prizesComp = computed(() => {
  const sweepPrizes = useSweepstakePrizes(props.prizes);
  if (props.metadata?.prizeValueOverride) {
    sweepPrizes[0].prizeValueOverride = props.metadata.prizeValueOverride;
  }
  return sweepPrizes;
});
const prize = computed(() => {
  const prizeLocal = prizesComp.value?.[0];
  if (prizesComp.value.length === 1 && ['StakedCash', 'StakedToken',].includes(prizeLocal.type) && props.assets) {
    prizeLocal.imageUrl = props.assets.find(asset => asset?.key === 'hero')?.url || null;
    prizeLocal.animationUrl = props.assets.find(asset => asset?.key === 'animation')?.url || null;
    prizeLocal.value = prizeLocal.type === 'StakedCash' ? prizeLocal.amount : 0;
  }
  return prizeLocal;
});
const showMultiPrizeComponents = computed(() => prizesComp.value.length > 1 && props.presentationType === 'Competition' && props.mechanism === 'Standard');
const overtimeActive = computed(() => stateComp.value !== 3 ? false : props.overtime?.active);
const tokenId = computed(() => {
  if (showMultiPrizeComponents.value) {
    const prizeLocal = prizesComp.value[currentPrizeIndex.value] || null;
    return prizeLocal ? $getNFTTokenNumber(prizeLocal.name, prizeLocal.tokenId) : null;
  } else {
    if (prize.value?.shortName === 'MWCT') { return null; } // TODO: TEMP: for cybertruck whatsapp
    return prize.value ? $getNFTTokenNumber(prize.value.name, prize.value.tokenId) : null;
  }
});

const estimatedEntryCount = computed(() => {
  if (props.metadata?.useEntryCount || !props.entryEstimate?.max) { return props.entryCount; }

  return props.entryEstimate.max - props.entryEstimate.remaining;
});
const stateComp = computed(() => Number(props.state));
const winner = computed(() => !props.winners ? null : props.winners[0]);
const winnerDate = computed(() => !winner.value ? null : $dayjs(winner.value.date).fromNow());
const passwordRequired = computed(() => {
  if (props.entries?.length) { return false; }
  if (props.metadata?.password) {
    return !passwordsPassed.value.find(o => o.id === props.id)?.passed;
  } else {
    return false;
  }
});
const isFull = computed(() => props.closeCondition?.max > 0 && props.closeCondition?.remaining <= 0);

const userTagRequired = computed(() => {
  if (props.entries?.length) { return false; }

  let requiredTags = props.metadata?.tags;

  if (!requiredTags) { return false; }

  if (!Array.isArray(requiredTags)) { requiredTags = [requiredTags,]; }

  const userTags = userData.value?.tags ?? [];

  if (userTags.length === 0) { return true; }

  const matchType = props.metadata?.tagsMatchType ?? 'ANY';
  let passed = false;

  switch (matchType) {
    case 'ALL':
      passed = requiredTags.every(t => userTags.includes(t));
      break;

    default:
      passed = requiredTags.some(t => userTags.includes(t));
      break;
  }

  return !passed;
});
const isPublic = computed(() => props.public);
const landingLink = computed(() => {
  if (props.metadata?.placeholder?.externalLink) { return props.metadata.placeholder.externalLink; }

  if (props.metadata?.placeholder?.landingDisabled) { return null; }

  return `/competition/${props.id}`;
});
const animationUrl = computed(() => isMobileDevice.value ? null : prize.value?.animationUrl);
const drawingStateUi = computed(() => !![5, 6,].includes(stateComp.value));
const isHot = computed(() => !!((props.category?.name === 'head-to-head' && props.participants > 0 && stateComp.value === 3)));
const showClosedButton = computed(() => {
  if ((props.hideEndedButton && stateComp.value === 8) || stateComp.value < 4) { return false; }
  return true;
});
const supplimentImageUrl = computed(() => props.metadata?.supplimentImageUrl);

init();

function cardAction(e) {
  if (e.target.classList.contains('js-block-card-action')) { return; }

  if (props.metadata?.placeholder?.externalLink) {
    handleOpenNewTab(props.metadata.placeholder.externalLink);
    return;
  }

  if (props.metadata?.placeholder?.modalDisabled) {
    return;
  }

  if (isFull.value || props.forceLinkToCompetition) {
    return router.push({ path: `/competition/${props.id}`, });
  }

  if ([3,].includes(stateComp.value) && (!isClosed.value || (isClosed.value && props.overtime?.applicable === true))) {
    emits('card-action');
  }
}

function init() {
  const contractMetadata = {
    ...props.blockchain?.contract?.metadata,
    ...props.metadata,
  };
  const requirements = props.blockchain?.entryRequirements || null;
  const requirementsNftCount = contractMetadata?.nftRestrictionCount || 1;
  if (requirements) {
    for (const requirement of requirements) {
      if (requirement.type === 'NFT') {
        nftRequirement.exist = true;
        nftRequirement.items.push(requirement);
      }
    }
    nftRequirement.passes = checkRequirementsPass(requirements, requirementsNftCount);
  }
  if (contractMetadata?.erc20Gated) {
    erc20Requirement.exists = true;
    erc20Requirement.network = props.blockchain?.network;
    erc20Requirement.contractAddress = contractMetadata?.erc20Address;
    erc20Requirement.name = contractMetadata?.erc20Name;
    erc20Requirement.imageUrl = contractMetadata?.erc20ImageUrl;
    erc20Requirement.minBalance = contractMetadata?.erc20MinBalance;
    erc20Requirement.marketplaceUrl = contractMetadata?.marketplaceUrl;
    erc20Requirement.pass = checkERC20RequirementsPass(contractMetadata?.erc20Address, contractMetadata?.erc20MinBalance);
  }

  if (contractMetadata?.uncxStakingGated) {
    uncxStakingRequirement.exists = true;
    uncxStakingRequirement.network = props.blockchain?.network;
    uncxStakingRequirement.contractAddress = contractMetadata?.uncxStakingAddress;
    uncxStakingRequirement.name = contractMetadata?.uncxStakingTokenName;
    uncxStakingRequirement.imageUrl = contractMetadata?.uncxStakingImageUrl;
    uncxStakingRequirement.minBalance = contractMetadata?.uncxStakingMinBalance;
    uncxStakingRequirement.pass = checkUNCXStakingPass(contractMetadata?.uncxStakingAddress, contractMetadata?.uncxStakingMinBalance);
  }

  checkIsClosed();
  hasReminder.value = props.reminder;
}

function checkIsClosed() {
  if (!props.closeTime) { return; }
  const now = $dayjs();
  const endDate = $dayjs(props.closeTime);
  const expiresIn = endDate.diff(now, 's');
  if (expiresIn <= 0) {
    isClosed.value = true;
  } else {
    isClosed.value = false;
  }
}

function setPrizeIndex(prizeIndex) {
  currentPrizeIndex.value = prizeIndex.index;
  currentPrizeDirection.value = prizeIndex.direction;
}

watch(
  () => secondsTicker.value,
  () => {
    checkIsClosed();
  }
);
</script>
