<template>
  <div
    class="absolute h-full w-full overflow-hidden"
    :class="{'bg-slate-900 ' : !firstTimeCount}"
  >
    <div
      :key="indexReset"
      class="absolute top-1 left-0 w-full flex flex-nowrap justify-evenly z-20 px-3"
    >
      <div
        v-for="index in prizes"
        :key="index"
        class="bg-white/30 w-full h-1 rounded-full mx-0.5 shadow-lg relative overflow-hidden"
      >
        <transition :name="transitionProgress" appear>
          <span
            v-if="(!firstTimeCount && ((transitionProgress && currentIndex >= index) || (!transitionProgress && currentIndex === index))) || (isInteracted && currentIndex === index)"
            class="bg-white h-full w-full absolute top-0 left-0"
            :class="{'!transition-none' : isInteracted}"
          />
        </transition>
      </div>
    </div>

    <!-- Arrows -->
    <button v-if="showArrows" class="absolute top-1/2 left-1 z-20" @click="switchIndex('prev')">
      <span class="icon-ico-carret-left font-bold text-3xl"/>
    </button>
    <button v-if="showArrows" class="absolute top-1/2 right-1 z-20" @click="switchIndex('next')">
      <span class="icon-ico-carret-right font-bold text-3xl"/>
    </button>

    <!-- Media display -->
    <transition-group :name="transitionImage">
      <ImageLoader
        v-for="(item, index) in prizesFiltered"
        v-show="index === currentIndex"
        :key="item?.id"
        class="!absolute !transition-all"
        :classes="[
          padded ? 'p-1 rounded-2xl' : ''
        ]"
        :card-dark="cardDark"
        :image-url="$imgSrcResize(item.imageUrl, 650, 650)"
        :animation-url="item.animationUrl"
        :width="width"
        :height="height"
        loading-type="eager"
        allow-video-on-hover
      />
    </transition-group>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useUiStore } from '@/store/ui';

const props = defineProps({
  prizes: {
    type: Array,
    default: null,
  },
  cardDark: {
    type: Boolean,
    default: false,
  },
  width: {
    type: [String, Number,],
    default: null,
  },
  height: {
    type: [String, Number,],
    default: null,
  },
  padded: {
    type: Boolean,
    default: false,
  },
  showArrows: {
    type: Boolean,
    default: false,
  },
  autoPlay: {
    type: Boolean,
    default: false,
  },
  forceIndex: {
    type: Number,
    default: null,
  },
  forceDirection: {
    type: String,
    default: null,
  },
  allowForceIndex: {
    type: Boolean,
    default: false,
  },
  carouselInteracted: {
    type: Boolean,
    default: false,
  },
});

const uiStore = useUiStore();
const {
  secondsTicker,
} = storeToRefs(uiStore);

const currentIndex = ref(0);
const secondsCount = ref(-1);
const indexReset = ref(false);
const transitionProgress = ref(null);
const isAutoPlay = ref(false);
const isInteracted = ref(false);
const firstTimeCount = ref(true);
const transitionImage = ref('carousel-right');

const prizesFiltered = computed(() => props.prizes.filter(prize => !prize.reward));

function start() {
  if (document.visibilityState === 'visible') {
    currentIndex.value = 0;
    secondsCount.value = -1;
    indexReset.value = false;
    transitionProgress.value = null;
    isAutoPlay.value = false;
    isInteracted.value = false;
    firstTimeCount.value = true;
    if (props.autoPlay) {
      isAutoPlay.value = true;
    }
  }
}

function setNewIndex(direction) {
  if (direction === 'next') {
    transitionImage.value = 'carousel-right';
    currentIndex.value = currentIndex.value + 1;
  } else if (direction === 'prev') {
    transitionImage.value = 'carousel-left';
    currentIndex.value = currentIndex.value - 1;
  }

  if (currentIndex.value < 0) {
    currentIndex.value = prizesFiltered.value.length - 1;
    indexReset.value = !indexReset.value;
  }

  if (currentIndex.value >= prizesFiltered.value.length) {
    currentIndex.value = 0;
    indexReset.value = !indexReset.value;
  }
}

function switchIndex(direction) {
  isAutoPlay.value = false;
  transitionProgress.value = null;
  isInteracted.value = true;
  setNewIndex(direction);
}

watch(
  () => secondsTicker.value,
  () => {
    if (prizesFiltered.value.length < 2 || isInteracted.value) { return; }
    if (secondsCount.value === 0 && firstTimeCount.value) {
      transitionProgress.value = 'prize-progress';
      firstTimeCount.value = false;
      return;
    }
    secondsCount.value++;

    if (!isAutoPlay.value) { return; }

    if (secondsCount.value === 3) {
      secondsCount.value = 0;
      setNewIndex('next');
    }
  }
);

watch(
  () => props.forceIndex,
  (newVal) => {
    if (props.allowForceIndex) {
      if (props.forceDirection === 'prev') {
        transitionImage.value = 'carousel-left';
      } else {
        transitionImage.value = 'carousel-right';
      }
      currentIndex.value = newVal;
    }
  }
);

watch(
  () => currentIndex.value,
  (newVal) => {
    if (newVal === 0 && !firstTimeCount.value) {
      indexReset.value = !indexReset.value;
    }
  }
);

watch(
  () => props.carouselInteracted,
  () => {
    isAutoPlay.value = false;
    transitionProgress.value = null;
    isInteracted.value = true;
  }
);

onMounted(() => {
  start();
  document.addEventListener('visibilitychange', start());
});

onUnmounted(() => {
  document.removeEventListener('visibilitychange', start());
});
</script>
