<template>
  <div class="rounded-b-xl w-full overflow-hidden relative flex items-center justify-center py-1.5 cursor-pointer">
    <div
      class="relative z-10 text-center px-1 whitespace-nowrap leading-none flex flex-col"
      :class="btn.bgRainbow || metadata?.btnTheme === 'pure-gold' ? 'text-slate-800' : 'text-white'"
    >
      <span class="font-bold text-sm mb-0.5">
        {{ btn.text }}
      </span>
      <span class="text-2xs text-white/75">
        {{ participants ? `${participants} Entered` : '&nbsp;' }}
      </span>
    </div>
    <div
      class="absolute z-0 left-0 top-0 w-full h-full before:absolute transition-all"
      :class="[
        btn.bgRainbow ? 'bg-rainbow-pastel' : 'bg-blue-500 lg:group-hover/card:bg-blue-400',
        metadata?.btnTheme === 'pure-gold' ? '!bg-pure-gold-gradient !bg-white lg:group-hover/card:opacity-80' : '',
      ]"
    />
  </div>
</template>

<script setup>
const { $dayjs, } = useNuxtApp();

const props = defineProps({
  closeTime: {
    type: String,
    default: null,
  },
  isClosed: {
    type: Boolean,
    default: false,
  },
  metadata: {
    type: Object,
    default: null,
  },
  overtime: {
    type: Object,
    default: null,
  },
  participants: {
    type: Number,
    default: null,
  },
  isFull: {
    type: Boolean,
    default: false,
  },
});

const btn = computed(() => {
  if (props.metadata?.placeholder?.cardButtonLabel) {
    return { text: props.metadata?.placeholder?.cardButtonLabel, bgRainbow: false, };
  }

  const cardEnterButtonLabel = props.isFull ? 'Full' : (props.metadata?.cardEnterButtonLabel || 'Enter Now');

  if (!props.closeTime) {
    return { text: cardEnterButtonLabel, bgRainbow: false, };
  }

  if (props.isClosed && props.overtime?.applicable === false) {
    return { text: 'Drawing soon', bgRainbow: true, };
  }

  const now = $dayjs();
  const endDate = $dayjs(props.closeTime);
  const expiresIn = endDate.diff(now, 's');

  if ((expiresIn <= 0) && props.overtime?.applicable === false) {
    return { text: 'Drawing soon', bgRainbow: true, };
  }

  return { text: cardEnterButtonLabel, bgRainbow: false, };
});
</script>
