<template>
  <div
    class="rounded-full overflow-hidden absolute"
    :class="compact ? 'bottom-1 right-1 text-3xs' : 'bottom-[6px] right-[6px] text-2xs'"
  >
    <div class="relative z-10 tracking-wider font-bold text-center px-2 py-[2px] rounded-full bg-blue-600">
      <span class="truncate" v-html="expiresInText"/>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';

import { useUiStore } from '@/store/ui';
import { useDeviceStore } from '@/store/device';

const props = defineProps({
  drawTime: {
    type: String,
    default: null,
  },
  compact: {
    type: Boolean,
    default: false,
  },
  overtime: {
    type: Object,
    default: null,
  },
});

const { $dayjs, } = useNuxtApp();

const uiStore = useUiStore();
const {
  secondsTicker,
} = storeToRefs(uiStore);

const deviceStore = useDeviceStore();
const {
  isMobileDevice,
} = storeToRefs(deviceStore);

const expiresInText = ref();

setExpiresIn();

function setExpiresIn() {
  const now = $dayjs();
  const endDate = $dayjs(props.drawTime);
  const expiresIn = endDate.diff(now, 's');

  if (expiresIn <= 0) {
    const overtime = expiresInText.value = props.overtime?.applicable;
    return overtime
      ? 'Overtime'
      : isMobileDevice.value
        ? 'Soon'
        : 'Drawing soon';
  }

  let prefix = 'Draws';
  if (expiresIn < 86400) {
    prefix += ' in';
  }
  expiresInText.value = `${prefix} <span class="tabular-nums">${endDate.fromNow(true)}</span>`;
}

watch(
  () => secondsTicker.value,
  () => {
    setExpiresIn();
  }
);
</script>
