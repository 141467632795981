<template>
  <span
    class="max-xs:text-[10px] max-xs:px-2 max-2xs:px-1 text-3xs uppercase bg-blue-500 backdrop-blur-md sm:font-bold py-1 px-3 rounded-full transition-all mb-1"
    :class="[
      labelColorClass,
      labelTextColorClass,
      { 'cursor-pointer' : clickable }
    ]"
    @click.stop="showModal()"
  >
    {{ labelText }}
  </span>
</template>

<script setup>
import { useUiStore } from '@/store/ui';

const props = defineProps({
  labelText: {
    type: String,
    default: null,
  },
  labelTextColor: {
    type: String,
    default: null,
  },
  labelColor: {
    type: String,
    default: null,
  },
  labelColorShade: {
    type: String,
    default: null,
  },
  infoModalId: {
    type: String,
    default: null,
  },
});

const uiStore = useUiStore();
const {
  openInfoCmsModal,
} = uiStore;

const labelColorClass = computed(() => !props.labelColor ? null : `bg-${props.labelColor}-${props.labelColorShade || '700'}`);
const labelTextColorClass = computed(() => !props.labelTextColor ? 'text-white' : `text-${props.labelTextColor}`);
const clickable = computed(() => !!props.infoModalId);

function showModal() {
  if (clickable.value) {
    openInfoCmsModal({ modalId: props.infoModalId, });
  }
}
</script>
