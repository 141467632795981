<template>
  <div class="rounded-b-xl w-full overflow-hidden relative flex items-center justify-center mt-0.5 py-[.95rem] min-h-[46px]">
    <div class="relative z-10 tracking-wider font-bold text-2xs md:text-xs text-center px-1 whitespace-nowrap leading-none">
      <transition name="slideup-fade" mode="out-in">
        <div v-if="opensIn" class="animate-fade-in truncate">
          <span v-html="opensIn"/>
        </div>
      </transition>
    </div>

    <div class="absolute z-0 left-0 top-0 w-full h-full before:absolute bg-slate-300"/>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useUiStore } from '@/store/ui';

const props = defineProps({
  openTime: {
    type: String,
    default: null,
  },
});

const { $dayjs, } = useNuxtApp();

const uiStore = useUiStore();
const {
  secondsTicker,
} = storeToRefs(uiStore);

const opensIn = ref(null);

setOpensIn();

function setOpensIn() {
  const now = $dayjs();
  const openDate = $dayjs(props.openTime);
  const opensInLocal = openDate.diff(now, 's');
  if (opensInLocal <= 0) {
    opensIn.value = 'Opens 00:00:00';
    return;
  }
  if (opensInLocal > 3600) {
    opensIn.value = `Opens in <span class="tabular-nums">${openDate.fromNow()}</span>`;
    return;
  }

  const totalMinutes = Math.floor(opensInLocal / 60);
  const totalSeconds = opensInLocal - (totalMinutes * 60);
  opensIn.value = `Opens in <span class="tabular-nums">${totalMinutes ? totalMinutes + 'm' : ''} ${totalSeconds ? totalSeconds + 's' : ''}</span>`;
}

watch(
  () => secondsTicker.value,
  () => {
    setOpensIn();
  }
);
</script>
