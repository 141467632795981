<template>
  <footer>
    <div
      v-if="state !== 7"
      class="text-center text-2xs max-2xs:text-3xs font-bold pt-1 pb-1.5 whitespace-nowrap text-slate-200"
    >
      <template v-if="!metadata?.hideMaxEntries && closeCondition?.type === 'Fund' && entryEstimate?.max && !winner">
        <span :class="textColor">{{ estimatedEntryCount }}</span> of <span :class="textColor">{{ entryEstimate?.max }}</span> max entries
      </template>

      <template v-else-if="!metadata?.hideMaxEntries && closeCondition?.type === 'Entry' && !winner">
        <span :class="textColor">{{ (closeCondition.max - closeCondition.remaining) }}</span> of <span :class="textColor">{{ closeCondition.max }}</span> max entries
      </template>

      <template v-else-if="!metadata?.hideMaxParticipants && closeCondition?.type === 'Participant' && !winner">
        <span :class="textColor">{{ (closeCondition.max - closeCondition.remaining) }}</span> of <span class="text-red-500">{{ maxParticipants }} Max<span class="max-xs:hidden">imum</span></span>
      </template>

      <template v-else-if="metadata?.useEntryCount && !winner">
        <span :class="textColor">{{ entryCount }}</span> {{ entryCount !== 1 ? 'entries' : 'entry' }}
      </template>

      <template v-else-if="participants">
        <template v-if="!winner && !winners"><span class="max-2sm:hidden">Join</span>  <span :class="textColor">{{ participants }}</span> {{ $pluralize(participants, 'MetaWinner', 's', false) }}</template>
      </template>
    </div>

    <div v-if="metadata?.placeholder?.footerText || metadata?.placeholder?.footerText2" class="mt-1">
      <h3
        class="text-xs relative w-full text-center text-slate-200 leading-none"
        :class="{
          'font-bold': metadata.placeholder?.footerText,
          'mt-1': metadata.placeholder?.footerText2,
        }"
      >
        {{ metadata.placeholder.footerText ?? metadata.placeholder.footerText2 }}
      </h3>
    </div>
  </footer>
</template>

<script setup>
const props = defineProps({
  colorTheme: {
    type: String,
    default: null,
  },
  metadata: {
    type: Object,
    default: null,
  },
  closeCondition: {
    type: Object,
    default: null,
  },
  entryEstimate: {
    type: Object,
    default: null,
  },
  winner: {
    type: Boolean,
    default: false,
  },
  winners: {
    type: Array,
    default: null,
  },
  estimatedEntryCount: {
    type: [String, Number,],
    default: null,
  },
  participants: {
    type: Number,
    default: null,
  },
  state: {
    type: Number,
    default: null,
  },
});

const textColor = computed(() => props.colorTheme === 'dark' ? 'text-white' : 'text-black');
const maxParticipants = computed(() => props.metadata?.maxParticipants ?? props.closeCondition?.max);
</script>
