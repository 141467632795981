<template>
  <div class="rounded-b-xl w-full overflow-hidden relative flex items-center justify-center mt-0.5 py-3 h-[46px]">
    <div
      class="relative z-10 tracking-wider font-bold text-xs text-center px-1 whitespace-nowrap leading-none"
      :class="[[4,5,6].includes(state) ? 'text-slate-800 [text-shadow:_0_1px_0_rgb(255_255_255_/_20%)]' : 'text-white [text-shadow:_0_1px_0_rgb(0_0_0_/_20%)]']"
    >
      <span v-if="[4].includes(state)">
        {{ isFull ? 'Full' : 'Drawing soon...' }}
      </span>
      <span v-if="[5,6].includes(state)" class="animate-pulse">Drawing...</span>
      <span v-if="state == 8 && winner">Ended {{ winnerDate }}</span>
      <span v-if="state == 8 && !winner">Ended {{ $dayjs(closeTime).fromNow() }}</span>
      <span v-if="[7,9].includes(state)">Cancelled</span>
    </div>
    <div
      class="absolute left-0 top-0 w-full h-full before:absolute"
      :class="{
        'bg-rainbow-pastel' : [4,5,6].includes(state),
        'bg-blue-600' : [1,2,3].includes(state),
        'bg-slate-300' : state > 6
      }"
    />
  </div>
</template>

<script setup>
defineProps({
  state: {
    type: Number,
    default: null,
  },
  isFull: {
    type: Boolean,
    default: null,
  },
  winner: {
    type: Boolean,
    default: false,
  },
  winnerDate: {
    type: String,
    default: null,
  },
  closeTime: {
    type: String,
    default: null,
  },
});

const { $dayjs, } = useNuxtApp();
</script>
